import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FirestoreProvider } from '@react-firebase/firestore';
import { config } from './firebase/firebaseConfigs';
import getPlainRoutes from './routes';

import Header from './Components/Header/Header';
import Header2 from './NewComponents/Header/Header';
import Footer2 from './NewComponents/Footer/Footer';
import Footer from './Components/Footer/Footer';
import Cookies from './Components/common/Cookies/Cookies';
import useLocalStorage from './Components/common/LocalStorage';
import ContactUsModal from './Components/ContactUsModal/ContactUsModal';
import ScrollToTop from './Components/common/ScrollToTop/ScrollToTop';

import { ReactComponent as ContactUs } from './Components/Icons/contactUs.svg';
import { ReactComponent as Close } from './Components/Icons/close.svg';

import Subscribe from './Components/Subscribe/Subscribe';

import './App.scss';

export default function App() {
	const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('cookies', '');
	const [cookiesClosed, setCookiesClosed] = useState(false);
	const [covidOpened, setCovidOpened] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const [extraStaffButton, setExtraStaffButton] = useState(false);
	const [closedExtraStaff, setClosedExtraStaff] = useState(false);

	setTimeout(() => setExtraStaffButton(true), 30000);
	clearTimeout(setTimeout(() => setExtraStaffButton(true), 30000));

	// redirect to /hire

	// const history = useHistory();

	// useEffect(() => {
	// 	if (history.location.pathname === '/') {
	// 		history.push('/hire');
	// 		window.location.reload();
	// 	}
	// }, [history]);

	return (
		<FirestoreProvider firebase={firebase} {...config}>
			<HelmetProvider>
				<Router>
					<ScrollToTop>
						<Helmet>
							<title>GSS | Global Staffing Support</title>
							<meta
								name="description"
								content={
									'Markets are changing. New demands. New realities. Remote work is the world’s new standard. Global Staffing Support has extensive experience with remote workers. We provide experts in all major IT fields in addition to your workforce and integrate them seamlessly, all working remotely in Ukraine.'
								}
							/>
							<meta property="og:title" content="Global Staffing Support" />
							<meta property="og:description" content={'Best IT outstaffing in Ukraine'} />
						</Helmet>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'privacy',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'blog',
								'article',
								'myteam',
								'softwareteam',
								'supportteam',
								'wiki',
								'wikiArticle',
								'questionnaire',
								'questionnaireBali',
								'contactUsForm',
								/////////////////////////////////
								'home2',
								'whyus',
								'aboutus',
								'gethired',
								'contactUs',
								'successStories',
								'successStoriesDetails/:slug',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									{path.startsWith('/v2') ? <Header2 /> : <Header setCovidOpened={setCovidOpened} covidOpened={covidOpened} />}
								</Route>
							))}
						</Switch>
						<div className="componentWrapper">
							<Switch>
								<Redirect from="/en" to="/" />
								<Redirect from="/ua" to="/" />
								{getPlainRoutes().map(({ path, component: Component, exact }) => (
									<Route path={path} render={(props) => <Component {...props} />} exact={exact} key={path || '/404'} />
								))}
							</Switch>
						</div>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'blog',
								'article',
								'techTalent',
								'wiki',
								'wikiArticle',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									<Subscribe />
								</Route>
							))}
						</Switch>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'privacy',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'blog',
								'article',
								'myteam',
								'softwareteam',
								'supportteam',
								'wiki',
								'wikiArticle',
								'questionnaire',
								'questionnaireBali',
								'contactUsForm',
								/////////////////////////////////
								'home2',
								'whyus',
								'aboutus',
								'gethired',
								'contactUs',
								'successStories',
								'successStoriesDetails/:slug',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									{path.startsWith('/v2') ? <Footer2 /> : <Footer />}
									{!covidOpened && extraStaffButton && !closedExtraStaff && (
										<button onClick={() => setContactModal(true)} className="extraStaffContactButton">
											<span className="extraStaffContactText">Send us message</span>
											<ContactUs />
											<button
												onClick={(e) => {
													e.stopPropagation();
													setClosedExtraStaff(true);
												}}
												className="closeExtraStaffContact"
											>
												<Close />
											</button>
										</button>
									)}
									{cookiesAccepted !== 'accepted' && !cookiesClosed && (
										<Cookies setCookiesAccepted={setCookiesAccepted} setCookiesClosed={setCookiesClosed} />
									)}
								</Route>
							))}
						</Switch>
						{setContactModal && <ContactUsModal contactModal={contactModal} setContactModal={setContactModal} extraStaff />}
					</ScrollToTop>
				</Router>
			</HelmetProvider>
		</FirestoreProvider>
	);
}
